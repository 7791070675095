<template>
    <v-sheet class="sheet"
             elevation="1"
             @click="stopClick($event)">
        <l-map :center="center"
               ref="map"
               v-resize="onResize"
               @ready="onReady"
               @update:center="centerUpdate"
               @update:zoom="zoomUpdate"
               :zoom="zoom">
            <l-tile-layer :attribution="attribution"
                          :url="url"></l-tile-layer>
            <l-marker :lat-lng.sync="markerPosition"
                      v-if="showMarker"
                      :icon="defaultIcon"
                      :draggable="draggable"></l-marker>
            <l-marker v-if="additionalMarker"
                      :icon="additionalMarkerIcon"
                      :lat-lng="[additionalMarkerLat, additionalMarkerLng]"></l-marker>
            <l-control v-if="showControl"
                       :position="'bottomleft'">
                <a @click="emitZoomMap($event)">
                    <v-icon>{{ ICONS.ZOOM }}</v-icon>
                </a>
            </l-control>
        </l-map>
        <v-row>
            <v-col v-if="markerDescription">
                <template>
                    <v-icon color="blue">{{ ICONS.MAP }}</v-icon>
                    <span class="caption">{{ markerDescription }}</span>
                </template>
            </v-col>
            <v-col v-if="additionalMarkerDescription">
                <template>
                    <v-icon :color="this.additionalMarkerCorrection ? 'green' : 'red'">{{ ICONS.MAP }}</v-icon>
                    <span class="caption">{{ additionalMarkerDescription }}</span>
                </template>
            </v-col>
        </v-row>
    </v-sheet>
</template>

<script>
import { Icon, icon, latLng, latLngBounds } from 'leaflet';
import { LControl, LMap, LMarker, LTileLayer } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
import { ICONS } from '@/constants/icons';

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

export default {
    name: 'MapInstance',
    components: { LMap, LTileLayer, LMarker, LControl },
    props: {
        lat: {
            type: [String, Number],
            'default': ''
        },
        lng: {
            type: [String, Number],
            'default': ''
        },
        draggable: {
            type: Boolean,
            'default': false
        },
        additionalMarker: {
            type: Boolean,
            'default': false
        },
        additionalMarkerLat: {
            type: [String, Number],
            'default': ''
        },
        additionalMarkerLng: {
            type: [String, Number],
            'default': ''
        },
        additionalMarkerCorrection: {
            type: Boolean,
            'default': false
        },
        zoom: {
            type: Number,
            'default': 13
        },
        showControl: {
            type: Boolean,
            'default': false
        },
        markerDescription: {
            type: String,
            'default': ''
        },
        additionalMarkerDescription: {
            type: String,
            'default': ''
        }
    },
    data: () => ({
        mapO: null,
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        attribution:
            '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        currentZoom: 11.5,
        currentCenter: {
            lat: 0,
            lng: 0
        },
        markerPosition: {
            lat: 0,
            lng: 0
        },
        defaultIcon: icon({
            iconUrl: 'data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHZpZXdCb3g9IjAgMCAxNzIgMTcyIj48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9Im5vbnplcm8iIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBzdHJva2UtbGluZWNhcD0iYnV0dCIgc3Ryb2tlLWxpbmVqb2luPSJtaXRlciIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIiBzdHJva2UtZGFzaGFycmF5PSIiIHN0cm9rZS1kYXNob2Zmc2V0PSIwIiBmb250LWZhbWlseT0ibm9uZSIgZm9udC13ZWlnaHQ9Im5vbmUiIGZvbnQtc2l6ZT0ibm9uZSIgdGV4dC1hbmNob3I9Im5vbmUiIHN0eWxlPSJtaXgtYmxlbmQtbW9kZTogbm9ybWFsIj48cGF0aCBkPSJNMCwxNzJ2LTE3MmgxNzJ2MTcyeiIgZmlsbD0ibm9uZSI+PC9wYXRoPjxnIGZpbGw9IiM2NDZlYTIiPjxwYXRoIGQ9Ik04NiwwYy0yNy45Mzg4LDAgLTUwLjE2NjY3LDIyLjIyNzg3IC01MC4xNjY2Nyw1MC4xNjY2N2MwLDQzIDUwLjE2NjY3LDEyMS44MzMzMyA1MC4xNjY2NywxMjEuODMzMzNjMCwwIDUwLjE2NjY3LC03OS41NjExOSA1MC4xNjY2NywtMTIxLjgzMzMzYzAsLTI3LjkzODggLTIyLjIyNzg2LC01MC4xNjY2NyAtNTAuMTY2NjcsLTUwLjE2NjY3ek04Niw2NC41Yy03Ljg5NDUzLDAgLTE0LjMzMzMzLC02LjQzODggLTE0LjMzMzMzLC0xNC4zMzMzM2MwLC03Ljg5NDUzIDYuNDM4ODEsLTE0LjMzMzMzIDE0LjMzMzMzLC0xNC4zMzMzM2M3Ljg5NDUzLDAgMTQuMzMzMzMsNi40Mzg4MSAxNC4zMzMzMywxNC4zMzMzM2MwLDcuODk0NTMgLTYuNDM4OCwxNC4zMzMzMyAtMTQuMzMzMzMsMTQuMzMzMzN6Ij48L3BhdGg+PC9nPjwvZz48L3N2Zz4=',
            iconSize: [32, 37],
            iconAnchor: [16, 37]
        }),
        correctIcon: icon({
            iconUrl: 'data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHZpZXdCb3g9IjAgMCAxNzIgMTcyIj48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9Im5vbnplcm8iIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBzdHJva2UtbGluZWNhcD0iYnV0dCIgc3Ryb2tlLWxpbmVqb2luPSJtaXRlciIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIiBzdHJva2UtZGFzaGFycmF5PSIiIHN0cm9rZS1kYXNob2Zmc2V0PSIwIiBmb250LWZhbWlseT0ibm9uZSIgZm9udC13ZWlnaHQ9Im5vbmUiIGZvbnQtc2l6ZT0ibm9uZSIgdGV4dC1hbmNob3I9Im5vbmUiIHN0eWxlPSJtaXgtYmxlbmQtbW9kZTogbm9ybWFsIj48cGF0aCBkPSJNMCwxNzJ2LTE3MmgxNzJ2MTcyeiIgZmlsbD0ibm9uZSI+PC9wYXRoPjxnIGZpbGw9IiM5NDliN2IiPjxwYXRoIGQ9Ik04NiwwYy0yNy45Mzg4LDAgLTUwLjE2NjY3LDIyLjIyNzg3IC01MC4xNjY2Nyw1MC4xNjY2N2MwLDQzIDUwLjE2NjY3LDEyMS44MzMzMyA1MC4xNjY2NywxMjEuODMzMzNjMCwwIDUwLjE2NjY3LC03OS41NjExOSA1MC4xNjY2NywtMTIxLjgzMzMzYzAsLTI3LjkzODggLTIyLjIyNzg2LC01MC4xNjY2NyAtNTAuMTY2NjcsLTUwLjE2NjY3ek04Niw2NC41Yy03Ljg5NDUzLDAgLTE0LjMzMzMzLC02LjQzODggLTE0LjMzMzMzLC0xNC4zMzMzM2MwLC03Ljg5NDUzIDYuNDM4ODEsLTE0LjMzMzMzIDE0LjMzMzMzLC0xNC4zMzMzM2M3Ljg5NDUzLDAgMTQuMzMzMzMsNi40Mzg4MSAxNC4zMzMzMywxNC4zMzMzM2MwLDcuODk0NTMgLTYuNDM4OCwxNC4zMzMzMyAtMTQuMzMzMzMsMTQuMzMzMzN6Ij48L3BhdGg+PC9nPjwvZz48L3N2Zz4=',
            iconSize: [32, 37],
            iconAnchor: [16, 37]
        }),
        incorrectIcon: icon({
            iconUrl: 'data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHZpZXdCb3g9IjAgMCAxNzIgMTcyIj48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9Im5vbnplcm8iIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBzdHJva2UtbGluZWNhcD0iYnV0dCIgc3Ryb2tlLWxpbmVqb2luPSJtaXRlciIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIiBzdHJva2UtZGFzaGFycmF5PSIiIHN0cm9rZS1kYXNob2Zmc2V0PSIwIiBmb250LWZhbWlseT0ibm9uZSIgZm9udC13ZWlnaHQ9Im5vbmUiIGZvbnQtc2l6ZT0ibm9uZSIgdGV4dC1hbmNob3I9Im5vbmUiIHN0eWxlPSJtaXgtYmxlbmQtbW9kZTogbm9ybWFsIj48cGF0aCBkPSJNMCwxNzJ2LTE3MmgxNzJ2MTcyeiIgZmlsbD0ibm9uZSI+PC9wYXRoPjxnIGZpbGw9IiM3ZTRmNGYiPjxwYXRoIGQ9Ik04NiwwYy0yNy45Mzg4LDAgLTUwLjE2NjY3LDIyLjIyNzg3IC01MC4xNjY2Nyw1MC4xNjY2N2MwLDQzIDUwLjE2NjY3LDEyMS44MzMzMyA1MC4xNjY2NywxMjEuODMzMzNjMCwwIDUwLjE2NjY3LC03OS41NjExOSA1MC4xNjY2NywtMTIxLjgzMzMzYzAsLTI3LjkzODggLTIyLjIyNzg2LC01MC4xNjY2NyAtNTAuMTY2NjcsLTUwLjE2NjY3ek04Niw2NC41Yy03Ljg5NDUzLDAgLTE0LjMzMzMzLC02LjQzODggLTE0LjMzMzMzLC0xNC4zMzMzM2MwLC03Ljg5NDUzIDYuNDM4ODEsLTE0LjMzMzMzIDE0LjMzMzMzLC0xNC4zMzMzM2M3Ljg5NDUzLDAgMTQuMzMzMzMsNi40Mzg4MSAxNC4zMzMzMywxNC4zMzMzM2MwLDcuODk0NTMgLTYuNDM4OCwxNC4zMzMzMyAtMTQuMzMzMzMsMTQuMzMzMzN6Ij48L3BhdGg+PC9nPjwvZz48L3N2Zz4=',
            iconSize: [32, 37],
            iconAnchor: [16, 37]
        }),
        undefinedPositions: ['', null],
        ICONS
    }),
    computed: {
        center () {
            return latLng(this.lat || 0, this.lng || 0);
        },
        additionalMarkerIcon () {
            return this.additionalMarkerCorrection ? this.correctIcon : this.incorrectIcon;
        },
        showMarker () {
            if (this.draggable) {
                return true;
            } else {
                return !this.undefinedPositions.includes(this.lat) &&
                    !this.undefinedPositions.includes(this.lng);
            }
        }
    },
    watch: {
        markerPosition: {
            handler (val) {
                this.$emit('update-coordinates', val);
            },
            immediate: true
        },
        lat: {
            handler (val) {
                let _val;
                if (val === '-' || !this.isCoordinate(val)) {
                    _val = 0;
                    this.$emit('update-coordinates', {
                        lat: 0,
                        lng: this.lng
                    });
                } else {
                    _val = val;
                }

                this.updateMarker(_val, this.lng);
            }
        },
        lng: {
            handler (val) {
                let _val;
                if (val === '-' || !this.isCoordinate(val)) {
                    _val = 0;
                    this.$emit('update-coordinates', {
                        lat: this.lat,
                        lng: 0
                    });
                } else {
                    _val = val;
                }

                this.updateMarker(this.lat, _val);
            }
        }
    },
    methods: {
        zoomUpdate (zoom) {
            this.currentZoom = zoom;
        },
        centerUpdate (center) {
            this.currentCenter = center;
        },
        onResize () {
            this.$refs.map.mapObject._onResize();
        },
        updateMarker (lt, ln) {
            this.markerPosition = latLng(lt, ln);
        },
        isCoordinate (val) {
            const regex = new RegExp(/^-?\d+(\.\d+)?/);
            return regex.test(val);
        },
        onReady () {
            const _this = this;
            this.mapO = this.$refs.map.mapObject;
            if (this.draggable) {
                this.mapO.on('contextmenu', function (e) {
                    _this.markerPosition = e.latlng;
                });
            }
            if (this.additionalMarker) {
                this.$nextTick(() => {
                    const corner1 = latLng(this.lat, this.lng);
                    const corner2 = latLng(this.additionalMarkerLat, this.additionalMarkerLng);
                    const bounds = latLngBounds(corner1, corner2);

                    this.$refs.map.mapObject.setZoom(13);
                    setTimeout(() => {
                        this.$refs.map.mapObject.flyToBounds(bounds);
                    }, 200);
                });
            }
        },
        emitZoomMap (e) {
            e.stopPropagation();
            this.$emit('zoom-map');
        },
        stopClick (e) {
            e.stopPropagation();
        }
    },
    created () {
        this.currentCenter = this.center;
        this.markerPosition = this.center;
    },
    mounted () {
        this.onResize();
    }
};
</script>

<style scoped lang="scss">
.sheet {
    height: 100%;
}
</style>
