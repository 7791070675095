<template>
    <Modal :modal-title="modalTitle"
           :dialog="dialog"
           max-width="1600px"
           data-test="modal"
           @close="close"
           @visibility="visibility($event)">
        <template slot="subtitle">
            <v-layout v-if="canDownloadPdf">
                <v-btn color="secondary"
                       small
                       @click="showSendMail"
                       class="mr-3">{{ $t('buttons.send_mail') }}
                </v-btn>
                <GetFile :loader="pdfLoader"
                        @get-file="getPdf"></GetFile>

                <SendMail :dialog="sendMailVisibility"
                          :callback="sendIncidentMail"
                          :default-title="`${$t('labels.incident_details')} ${activeIncident.number}`"
                          @close="closeSendMail"
                          @visibility="sendMailVisibility = $event"></SendMail>
            </v-layout>
        </template>
        <template slot="content">
            <v-row>
                <v-col cols="12">
                    <h3 class="font-weight-medium mb-3">{{ $t('labels.details') }}</h3>
                    <v-row>
                        <v-col md="8"
                               sm="12"
                               cols="12">
                            <v-sheet color="white"
                                     class="pa-4"
                                     elevation="1">
                                <v-layout align-stretch
                                          :wrap="isMobile">
                                    <v-flex sm6
                                            :class="{'bordered pr-4': smAndUp}">
                                        <InformationItem :title="$t('labels.customer')"
                                                         @edit="openEditForm()"
                                                         data-test="textShowIncidentFormButton"
                                                         name="customer"
                                                         :text="activeIncident.customer_name"></InformationItem>
                                        <InformationItem :title="$t('labels.site')"
                                                         @edit="openEditForm()"
                                                         name="site"
                                                         :text="activeIncident.place_name"></InformationItem>
                                        <InformationItem :title="$t('labels.location')"
                                                         @edit="openEditForm()"
                                                         name="location"
                                                         :text="activeIncident.point_name"></InformationItem>
                                        <InformationItem :title="$t('labels.location_time_from')"
                                                         @edit="openEditForm()"
                                                         name="incidents_date"
                                                         :text="activeIncident.parsed_local_start_date_time">
                                        </InformationItem>
                                        <InformationItem :title="$t('labels.location_time_to')"
                                                         @edit="openEditForm()"
                                                         name="end_date"
                                                         :text="activeIncident.parsed_local_end_date_time">
                                        </InformationItem>
                                    </v-flex>
                                    <v-flex sm6
                                            :class="{'bordered pl-4': smAndUp}">
                                        <InformationItem :title="$t('labels.incident_type')"
                                                         @edit="openEditForm()"
                                                         name="type">
                                                <Type v-if="activeIncident.incidentType"
                                                      :type="activeIncident.incidentType"></Type>
                                        </InformationItem>
                                        <InformationItem :title="$t('labels.status')"
                                                         @edit="openEditForm()"
                                                         name="status">
                                                <Status v-if="activeIncident.incidentStatus"
                                                        :status="activeIncident.incidentStatus"></Status>
                                        </InformationItem>
                                        <InformationItem :title="$t('labels.created_by')"
                                                         @edit="openEditForm()"
                                                         name="person"
                                                         :text="activeIncident.employee_name"></InformationItem>
                                        <InformationItem :title="$t('labels.responsible_person')"
                                                         @edit="openEditForm()"
                                                         name="person_assigned"
                                                         :text="activeIncident.responsible_employee_name"></InformationItem>
                                        <InformationItem :title="$t('labels.description')"
                                                         @edit="openEditForm()"
                                                         name="description"
                                                         :text="activeIncident.description"></InformationItem>
                                    </v-flex>
                                </v-layout>
                                <v-layout justify-end>
                                    <template v-if="showSourceInspection">
                                        <v-btn color="secondary"
                                               v-if="activeInspection"
                                               class="mr-3"
                                               @click="showInspectionDetails">{{ $t('labels.inspection_details') }}
                                        </v-btn>
                                        <InspectionDetails v-if="activeInspection"
                                                           @close="closeInspectionDetails"
                                                           :dialog="inspectionDetailsVisibility"
                                                           @visibility="inspectionDetailsVisibility = $event"></InspectionDetails>
                                    </template>
                                    <v-btn color="secondary"
                                           v-if="canEditIncident"
                                           data-test="editBtn"
                                           @click="openEditForm()">{{ $t('buttons.edit') }}</v-btn>
                                </v-layout>
                            </v-sheet>
                        </v-col>
                        <v-col md="4"
                               sm="12"
                               cols="12">
                            <Map :lat="activeIncident.latitude"
                                 :lng="activeIncident.longitude"></Map>
                        </v-col>
                    </v-row>
                </v-col>

                <v-col cols="12">
                    <v-row>
                        <v-col md="8"
                               sm="12"
                               cols="12">
                            <h3 class="font-weight-medium mb-3">{{ $tc('labels.question', 2) }}</h3>
                            <QuestionsTable :question="sortedQuestions"
                                            @load-more-items="getInspectionAnswersLazyLoading($event)"></QuestionsTable>
                        </v-col>
                        <v-col md="4"
                               sm="12"
                               cols="12">
                            <h3 class="font-weight-medium mb-3">{{ $t('labels.photos') }}</h3>
                            <div class="photosWrapper" v-if="!photosAreLoading">
                                <div v-for="image in activeIncidentPhotos" :key="image.id" class="photo">
                                    <PreviewImage :src="image.content"></PreviewImage>
                                </div>
                            </div>
                            <CircularLoader v-else />
                        </v-col>
                    </v-row>
                </v-col>

                <v-col cols="12">
                    <v-row>
                        <v-col md="8"
                               sm="12"
                               cols="12">
                            <h3 class="font-weight-medium mb-3">{{ $tc('labels.comment', 2) }}</h3>
                            <CommentsList></CommentsList>
                        </v-col>
                        <v-col md="4"
                               sm="12"
                               cols="12">
                            <h3 class="font-weight-medium mb-3">{{ $t('labels.statuses_log') }}</h3>
                            <StatusesLog :log="incidentHistory"></StatusesLog>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </template>
    </Modal>
</template>

<script>
import { mapState } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import Modal from '@/components/templates/Modal';
import InformationItem from '@/components/customers/InformationItem';
import Map from '@/components/widgets/Map';
import PreviewImage from '@/components/widgets/PreviewImage';
import StatusesLog from '@/components/incidents/StatusesLog';
import QuestionsTable from '@/components/questions/QuestionsTable';
import CommentsList from '@/components/incidents/CommentsList';
import InspectionDetails from '@/components/inspections/InspectionDetails';
import Type from '@/components/widgets/Type';
import Status from '@/components/widgets/Status';
import GetFile from '@/components/widgets/buttons/GetFile';
import SendMail from '@/components/widgets/dialogs/SendMail';
import { showBase64Document } from '@/helpers/files';
import CircularLoader from '@/components/widgets/CircularLoader.vue';
import { ActionStatus } from '@/constants/status';

export default {
    name: 'IncidentDetails',
    components: {
        CircularLoader,
        Modal,
        InformationItem,
        Map,
        PreviewImage,
        StatusesLog,
        QuestionsTable,
        CommentsList,
        Type,
        Status,
        GetFile,
        SendMail,
        InspectionDetails
    },
    props: {
        dialog: {
            type: Boolean,
            required: true
        }
    },
    data: () => ({
        pagination: {
            page: 1,
            amount: 20
        },
        pdfLoader: false,
        sendMailVisibility: false,
        inspectionDetailsVisibility: false,
        photosAreLoading: false
    }),
    computed: {
        ...mapState('manageIncident', [
            'activeIncident',
            'activeIncidentPhotos',
            'activeIncidentPhotosStatus',
            'incidentHistory',
            'incidentComments'
        ]),
        ...mapState('manageInspection', [
            'inspectionQuestions',
            'activeInspection'
        ]),
        ...mapFields('manageIncident', [
            'incidentFormVisibility',
            'editedIncidentId'
        ]),
        modalTitle () {
            return this.$t('labels.incident') + ' ' + this.activeIncident?.number;
        },
        isMobile () {
            return this.$vuetify.breakpoint.xs;
        },
        smAndUp () {
            return this.$vuetify.breakpoint.smAndUp;
        },
        canEditIncident () {
            return this.$can(this.$abilityActions.UPDATE, this.$abilitySubjects.INCIDENT);
        },
        canDownloadPdf () {
            return this.$can(this.$abilityActions.GET_REPORT_PDF, this.$abilitySubjects.INCIDENT);
        },
        showSourceInspection () {
            return this.activeInspection &&
                this.$can(this.$abilityActions.READ, this.$abilitySubjects.INSPECTION);
        },
        sortedQuestions () {
            return [...this.inspectionQuestions].sort((a, b) => (a.sequence === -1 || b.sequence === -1) ? -Infinity : a.sequence - b.sequence);
        }
    },
    watch: {
        activeIncidentPhotosStatus: {
            handler (val) {
                this.photosAreLoading = val === ActionStatus.PENDING;
            },
            immediate: true
        }
    },
    methods: {
        close () {
            this.$emit('close');
        },
        visibility (event) {
            this.$emit('visibility', event);
        },
        openEditForm () {
            if (this.canEditIncident) {
                this.$store.dispatch('manageIncident/getActiveIncident', this.activeIncident.id)
                    .then(() => {
                        this.$store.commit('manageIncident/SET_INCIDENT_DATA', this.activeIncident);
                        this.editedIncidentId = this.activeIncident.id;
                        this.incidentFormVisibility = true;
                    });
            }
        },
        getInspectionAnswers () {
            const payload = {
                id: this.activeIncident.inspectionWithAnswersId,
                page: this.pagination.page,
                amount: this.pagination.amount
            };

            return this.$store.dispatch('manageInspection/getInspectionAnswers', payload);
        },
        getInspectionAnswersLazyLoading (event) {
            this.pagination.page = event;

            const payload = {
                id: this.activeIncident.inspectionWithAnswersId || this.activeIncident.source_inspection_id,
                page: this.pagination.page,
                amount: this.pagination.amount
            };

            return this.$store.dispatch('manageInspection/getInspectionAnswersLazyLoading', payload);
        },
        getPdf () {
            this.pdfLoader = true;

            this.$store.dispatch('manageIncident/getIncidentPDF', this.activeIncident.id)
                .finally(() => {
                    this.pdfLoader = false;
                });
        },
        showSendMail () {
            this.sendMailVisibility = true;
        },
        closeSendMail () {
            this.sendMailVisibility = false;
            this.$store.commit('sendMail/RESET_MAIL_DATA');
        },
        sendIncidentMail () {
            return this.$store.dispatch('sendMail/sendIncidentMail', this.activeIncident.id);
        },
        showBase64Document: showBase64Document,
        showInspectionDetails () {
            this.inspectionDetailsVisibility = true;
        },
        closeInspectionDetails () {
            this.inspectionDetailsVisibility = false;
        }
    }
};
</script>

<style scoped>
.photosWrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    .photo {
        display: flex;
        line-height: 0;
    }
}
</style>
