<template>
    <Modal :modal-title="title"
           :dialog="incidentFormVisibility"
           max-width="1000px"
           :persistent="true"
           :closeIcon="false"
           data-test="modal"
           @keydown.enter="showConfirmation"
           @keydown.esc="closeWithConfirmation"
           @close="close"
           @visibility="visibility($event)">
        <template slot="content">
            <ManageIncidentForm ref="form"
                                @getCurrentIncidentQuestions="getCurrentIncidentQuestions"
                                :is-editable="isEditable"></ManageIncidentForm>
            <IncidentQuestionForm
                :isEditable="isEditable"
                ref="questionForm"
                :showQuestionForm="considerQuestions"
                @toggleQuestions="considerQuestions = !considerQuestions"
            />
            <ConfirmationDialog :dialog="showConfirmationDialog"
                                data-test="ConfirmationDialog"
                                @confirm="handleConfirmation($event)"
                                :text="$t('messages.save_changes')"
                                :action-button-title="$t('buttons.save')"></ConfirmationDialog>
            <span data-test="testKeydownSpan"></span>

            <ValidationError v-if="showError"></ValidationError>
        </template>

        <template slot="actions">
            <v-layout align-center
                      justify-end
                      class="mt-3">
                <v-btn color="secondary"
                       outlined
                       class="mr-3"
                       data-test="closeBtn"
                       @click="close">{{ $t('buttons.cancel') }}</v-btn>
                <v-btn color="secondary"
                       data-test="saveBtn"
                       :loading="loader"
                       :disabled="loader"
                       @click="save">{{ $t('buttons.save') }}</v-btn>
            </v-layout>
        </template>
    </Modal>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import { mapState } from 'vuex';
import Modal from '@/components/templates/Modal';
import ManageIncidentForm from '@/components/incidents/manageIncident/ManageIncidentForm';
import ConfirmationDialog from '@/components/widgets/dialogs/ConfirmationDialog';
import ValidationError from '@/components/widgets/ValidationError';
import IncidentQuestionForm from '@/components/incidents/manageIncident/questionForm/IncidentQuestionForm';

export default {
    name: 'ManageIncident',
    components: { Modal, ManageIncidentForm, ConfirmationDialog, ValidationError, IncidentQuestionForm },
    data: () => ({
        showQuestionForm: false,
        showConfirmationDialog: false,
        loader: false,
        showError: false,
        considerQuestions: false
    }),
    computed: {
        ...mapFields('manageIncident', [
            'incidentFormVisibility',
            'editedIncidentId'
        ]),
        ...mapState('manageIncident', [
            'showDetails'
        ]),
        title () {
            return this.editedIncidentId ? this.$t('incidents.edit_title') : this.$t('incidents.add_title');
        },
        isFieldsDirty () {
            return this.$refs.form.isFormDirty;
        },
        isEditable () {
            return this.editedIncidentId !== null;
        }
    },
    methods: {
        close () {
            this.incidentFormVisibility = false;
            this.clearFiles();
            if (!this.showDetails) {
                this.editedIncidentId = null;
                this.$store.commit('manageIncident/CLEAR_INCIDENT_DATA');
                this.$store.dispatch('manageIncident/clearIncidentData');
            }
            this.$refs.form.resetValidation();
            this.$refs.questionForm.resetValidation();
            this.showError = false;
            this.considerQuestions = false;
            this.$store.commit('manageIncident/CLEAR_INCIDENT_QUESTIONS');
        },
        clearFiles () {
            this.$refs.form.clearFiles();
        },
        visibility (event) {
            this.incidentFormVisibility = event;
        },
        closeWithConfirmation () {
            if (this.isFieldsDirty) {
                this.showConfirmationDialog = true;
            } else {
                this.close();
            }
        },
        showConfirmation () {
            if (this.isFieldsDirty) {
                this.showConfirmationDialog = true;
            }
        },
        updateIncident () {
            this.loader = true;

            this.$refs.form.validate()
                .then(valid => {
                    this.showError = !valid;
                    if (valid) {
                        return this.$store.dispatch('manageIncident/updateIncident')
                            .then(() => {
                                this.$emit('fetch');
                                if (this.showDetails) {
                                    this.$store.dispatch('manageIncident/getActiveIncident', this.editedIncidentId);
                                }
                                this.close();
                            })
                            .finally(() => {
                                this.loader = false;
                            });
                    } else {
                        this.loader = false;
                    }
                });
        },
        createIncident () {
            this.loader = true;
            let questionsValid = !this.considerQuestions;

            const validateMainForm = () => {
                this.$refs.form.validate()
                    .then(valid => {
                        this.showError = !valid;
                        if (valid && questionsValid) {
                            return this.$store.dispatch('manageIncident/createIncident', this.$refs.questionForm.getAnswers(), this.$refs.form.incidentType)
                                .then(() => {
                                    this.$emit('fetch');
                                    this.close();
                                })
                                .finally(() => {
                                    this.loader = false;
                                });
                        } else {
                            this.loader = false;
                        }
                    });
            };

            if (this.considerQuestions) {
                this.$refs.questionForm.validate()
                    .then(valid => {
                        this.showError = !valid;
                        this.loader = false;
                        if (valid) { questionsValid = true; }
                        validateMainForm();
                    });
            } else {
                validateMainForm();
            }
        },
        save () {
            if (this.isEditable) {
                this.updateIncident();
            } else {
                this.createIncident();
            }
        },
        handleConfirmation (event) {
            if (event) {
                this.save();
                this.showConfirmationDialog = false;
            } else {
                this.showConfirmationDialog = false;
            }
        },
        getCurrentIncidentQuestions (val) {
            this.$store.dispatch('manageIncident/getCurrentIncidentQuestions', val);
        }
    }
};
</script>

<style scoped>

</style>
