<template>
    <Modal :modal-title="modalTitle"
           :dialog="dialog"
           max-width="1600px"
           data-test="modal"
           @close="close"
           @visibility="visibility($event)">
        <template slot="subtitle">
            <v-layout align-center
                      class="mt-2"
                      v-if="canDownloadPdf">
                <GetFile :loader="pdfLoader"
                        @get-file="getPdf"></GetFile>
                <v-btn color="secondary"
                       small
                       @click="showSendMail"
                       class="ml-3">{{ $t('buttons.send_mail') }}</v-btn>
                <SendMail :dialog="sendMailVisibility"
                          :callback="sendInspectionMail"
                          :default-title="`${$t('labels.inspection_details')} ${activeInspection.id}`"
                          @close="closeSendMail"
                          @visibility="sendMailVisibility = $event"></SendMail>
            </v-layout>
        </template>
        <template slot="content">
            <h3 class="font-weight-medium mb-3">{{ $t('labels.details') }}</h3>
            <v-row class="mb-6">
                <v-col md="8"
                       sm="12"
                       cols="12">
                    <v-sheet color="white"
                             class="pa-4"
                             elevation="1">
                        <v-layout align-stretch
                                  :wrap="isMobile">
                            <v-flex sm6
                                    :class="{'bordered pr-4': smAndUp}">
                                <InformationItem :title="$t('labels.customer')"
                                                 name="customer"
                                                 :text="activeInspection.customer_name"></InformationItem>
                                <InformationItem :title="$t('labels.site')"
                                                 name="site"
                                                 :text="activeInspection.place_name"></InformationItem>
                                <InformationItem :title="$t('labels.site_code')"
                                                 name="site_code"
                                                 :text="activeInspection.place_code"></InformationItem>
                                <InformationItem :title="$t('labels.location')"
                                                 name="location"
                                                 :text="activeInspection.point_name"></InformationItem>
                                <InformationItem :title="$tc('labels.inspector', 1)"
                                                 name="inspector"
                                                 :text="activeInspection.inspector_name"></InformationItem>
                            </v-flex>
                            <v-flex sm6
                                    :class="{'bordered pl-4': smAndUp}">
                                <InformationItem :title="$tc('labels.template', 1)"
                                                 name="template"
                                                 :text="activeInspection.template_name"></InformationItem>
                                <InformationItem :title="$t('inspections.summary.headers.planned_inspection_date')"
                                                 name="planned_inspection_date"
                                                 :text="activeInspection.parsed_scheduled_inspection_date_local"></InformationItem>
                                <InformationItem :title="$t('inspections.summary.headers.inspection_time')"
                                                 name="inspection_time"
                                                 :text="activeInspection.parsed_local_end_date_time"></InformationItem>
                                <InformationItem :title="$t('labels.location_condition')"
                                                 name="location_condition">
                                    <template>
                                        <v-icon :color="stateIconColor(activeInspection)"
                                                class="mr-2">{{ stateIcon(activeInspection) }}</v-icon>
                                        {{ stateTooltip(activeInspection) }}
                                    </template>
                                </InformationItem>
                                <InformationItem :title="$t('labels.inspection_location')"
                                                 name="inspection_location">
                                    <template>
                                        <v-icon :color="placeIconColor(activeInspection)"
                                                class="mr-2">{{ placeIcon(activeInspection) }}</v-icon>
                                        {{ placeTooltip(activeInspection) }}
                                    </template>
                                </InformationItem>
                            </v-flex>
                        </v-layout>
                    </v-sheet>
                </v-col>
                <v-col md="4"
                       sm="12"
                       cols="12">
                    <Map :lat="activeInspection.point_latitude"
                         :lng="activeInspection.point_longitude"
                         :marker-description="markerDescription"
                         :additional-marker="showAdditionalMarker"
                         :additional-marker-correction="additionalMarkerCorrection"
                         :additional-marker-lat="additionalMarkerLat"
                         :additional-marker-lng="additionalMarkerLng"
                         :additional-marker-description="additionalMarkerDescription"
                         ></Map>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <h3 class="font-weight-medium mb-3">{{ $tc('labels.question', 2) }}</h3>
                    <QuestionsTable :question="inspectionQuestions"
                                    @load-more-items="getInspectionAnswersLazyLoading($event)"></QuestionsTable>
                </v-col>
            </v-row>
        </template>
    </Modal>
</template>

<script>
import { mapState } from 'vuex';
import { LOCATION_NOTES } from '@/constants/inspectionLocationNotes';
import Modal from '@/components/templates/Modal';
import InformationItem from '@/components/customers/InformationItem';
import Map from '@/components/widgets/Map';
import QuestionsTable from '@/components/questions/QuestionsTable';
import inspectionNotesIcons from '@/mixins/inspectionNotesIcons';
import SendMail from '@/components/widgets/dialogs/SendMail';
import GetFile from '@/components/widgets/buttons/GetFile';

export default {
    name: 'InspectionDetails',
    components: { Modal, InformationItem, Map, QuestionsTable, SendMail, GetFile },
    mixins: [inspectionNotesIcons],
    props: {
        dialog: {
            type: Boolean,
            required: true
        }
    },
    data: () => ({
        pdfLoader: false,
        sendMailVisibility: false,
        pagination: {
            page: 1,
            amount: 20
        }
    }),
    computed: {
        ...mapState('manageInspection', [
            'activeInspection',
            'inspectionQuestions'
        ]),
        modalTitle () {
            return this.$tc('labels.inspection', 1) + ' ' + this.activeInspection?.id;
        },
        isMobile () {
            return this.$vuetify.breakpoint.xs;
        },
        smAndUp () {
            return this.$vuetify.breakpoint.smAndUp;
        },
        additionalMarkerCorrection () {
            return this.activeInspection.location_note === LOCATION_NOTES.CORRECT;
        },
        showAdditionalMarker () {
            const forbiddenStatuses = [LOCATION_NOTES.WITHOUT_LOCATION, LOCATION_NOTES.EXPIRED];
            return !forbiddenStatuses.includes(this.activeInspection.location_note);
        },
        markerDescription () {
            return this.$t('labels.map_marker_description');
        },
        additionalMarkerDescription () {
            return this.$t('labels.additional_map_marker_description');
        },
        additionalMarkerLat () {
            return this.activeInspection.saved_latitude || '';
        },
        additionalMarkerLng () {
            return this.activeInspection.saved_longitude || '';
        },
        inspectionType () {
            const daily = this.activeInspection.day_shift ? this.$t('labels.daily_inspection') : '';
            const night = this.activeInspection.night_shift ? this.$t('labels.night_inspection') : '';

            return `${daily}, ${night}`;
        },
        canDownloadPdf () {
            return this.$can(this.$abilityActions.GET_REPORT_PDF, this.$abilitySubjects.INSPECTION);
        }
    },
    methods: {
        close () {
            this.$emit('close');
        },
        visibility (event) {
            this.$emit('visibility', event);
        },
        getPdf () {
            this.pdfLoader = true;
            this.$store.dispatch('manageInspection/getInspectionPDF', this.activeInspection.id)
                .finally(() => {
                    this.pdfLoader = false;
                });
        },
        showSendMail () {
            this.sendMailVisibility = true;
        },
        closeSendMail () {
            this.sendMailVisibility = false;
            this.$store.commit('sendMail/RESET_MAIL_DATA');
        },
        sendInspectionMail () {
            return this.$store.dispatch('sendMail/sendInspectionMail', this.activeInspection.id);
        },
        getInspectionAnswers () {
            const payload = {
                id: this.activeInspection.id,
                page: this.pagination.page,
                amount: this.pagination.amount
            };

            return this.$store.dispatch('manageInspection/getInspectionAnswers', payload);
        },
        getInspectionAnswersLazyLoading (event) {
            this.pagination.page = event;

            const payload = {
                id: this.activeInspection.id,
                page: this.pagination.page,
                amount: this.pagination.amount
            };

            return this.$store.dispatch('manageInspection/getInspectionAnswersLazyLoading', payload);
        }
    }
};
</script>

<style scoped>

</style>
