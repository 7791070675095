import debounce from 'lodash.debounce';
import { mapState, mapGetters } from 'vuex';
import { FILTERS_SEARCHING } from '@/constants/filtersModelNames';

export default {
    data: () => ({
        debouncedGetResponsibleEmployees: null,
        isResponsibleEmployeesLoading: false,
        searchResponsibleEmployees: null,
        responsibleEmployeesPaginationPage: 1
    }),
    computed: {
        ...mapState('employees', [
            'totalResponsibleEmployeesAmount'
        ]),
        ...mapGetters('employees', [
            'responsibleEmployees'
        ])
    },
    watch: {
        searchResponsibleEmployees: {
            handler (val) {
                if (!val || this.isResponsibleEmployeesLoading) {
                    return;
                }

                this.debouncedGetResponsibleEmployees(val);
            }
        }
    },
    methods: {
        getResponsibleEmployees (additionalParams = null) {
            this.isResponsibleEmployeesLoading = true;

            let params = {};
            // fix for items appearing in employee autocomplete, this may break items if new usages added
            // employee and item logic should be split, it generates a lot of confusion.
            // Problem occurs because this distinct abstractions are merged together in database table which is a huge mistake.
            params['filter[is_item]'] = false;

            if (typeof additionalParams === 'string') {
                params['filter[full_name][match]'] = additionalParams;
            } else if (typeof additionalParams === 'object') {
                params = { ...additionalParams };
            }

            return this.$store.dispatch('employees/getResponsibleEmployees', params)
                .then(() => {
                    this.responsibleEmployeesPaginationPage = 1;
                })
                .finally(() => {
                    this.isResponsibleEmployeesLoading = false;
                });
        },
        getResponsibleEmployeesLazyLoading (additionalParams = null) {
            if (this.totalResponsibleEmployeesAmount > this.responsibleEmployees.length) {
                this.isResponsibleEmployeesLoading = true;

                this.responsibleEmployeesPaginationPage += 1;

                let params = {};

                if (typeof additionalParams === 'object') {
                    params = { ...additionalParams };
                }

                params['page[number]'] = this.responsibleEmployeesPaginationPage;

                const _name = this.searchResponsibleEmployees || this.filtersSearching?.[FILTERS_SEARCHING.PERSON_ASSIGNED];

                if (_name) {
                    params['filter[full_name][match]'] = _name;
                }

                return this.$store.dispatch('employees/getResponsibleEmployeesLazyLoading', params)
                    .finally(() => {
                        this.isResponsibleEmployeesLoading = false;
                    });
            }
        }
    },
    created () {
        this.debouncedGetResponsibleEmployees = debounce(this.getResponsibleEmployees, 1000);
    }
};
