<template>
    <div>
        <h4 class="divider">{{ label }}</h4>
        <ValidationProvider
            :name="label"
            :vid="id"
        >
            <input
                type="hidden"
                :name="id"
                :value="label"
            />
        </ValidationProvider>
    </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

export default {
    name: 'QuestionHeader',
    components: { ValidationProvider },
    props: {
        id: {
            type: String
        },
        label: {
            type: String,
            'default': ''
        }
    }
};
</script>

<style scoped lang="scss">
@import "@/assets/css/_variables.scss";

.divider {
    padding-bottom: 5px;
    border-bottom: 1px solid $light_grey_2;
}
</style>
