import { LOCATION_NOTES } from '@/constants/inspectionLocationNotes';
import { ICONS } from '@/constants/icons';

export default {
    methods: {
        additionalClass (item) {
            if (item.location_note === LOCATION_NOTES.EXPIRED) {
                return 'expired';
            }
            if (item.location_note === LOCATION_NOTES.OTHER_LOCATION || item.location_note === LOCATION_NOTES.WITHOUT_LOCATION) {
                return 'complete';
            }
        },
        stateIcon (item) {
            if (item.is_correct && item.location_note !== LOCATION_NOTES.EXPIRED) {
                return ICONS.CHECK_CIRCLE;
            } else if (!item.is_correct) {
                return ICONS.ERROR_CIRCLE;
            }
            return '';
        },
        stateIconColor (item) {
            return item.is_correct ? 'secondary' : 'red';
        },
        stateTooltip (item) {
            if (item.is_correct && item.location_note !== LOCATION_NOTES.EXPIRED) {
                return this.$t('inspections.summary.state_tooltips.correct');
            } else if (!item.is_correct) {
                return this.$t('inspections.summary.state_tooltips.bad');
            }
            return '';
        },
        placeIcon (item) {
            switch (item.location_note) {
            case LOCATION_NOTES.CORRECT:
                return ICONS.MAP;
            case LOCATION_NOTES.OTHER_LOCATION:
                return ICONS.MAP_OFF;
            case LOCATION_NOTES.WITHOUT_LOCATION:
                return ICONS.INFORMATION;
            }
        },
        placeTooltip (item) {
            switch (item.location_note) {
            case LOCATION_NOTES.CORRECT:
                return this.$t('inspections.summary.location_tooltips.correct');
            case LOCATION_NOTES.OTHER_LOCATION:
                return this.$t('inspections.summary.location_tooltips.incorrect');
            case LOCATION_NOTES.WITHOUT_LOCATION:
                return this.$t('inspections.summary.location_tooltips.no_data');
            }
        },
        placeIconColor (item) {
            if (item.location_note === LOCATION_NOTES.OTHER_LOCATION || item.location_note === LOCATION_NOTES.WITHOUT_LOCATION) {
                return 'red';
            }
        }
    }
};
