<template>
    <div class="full-height">
        <portal to="modal" v-if="dialog">
            <div class="sheet"
                 @click="dialog = false"
                 :class="{'absolute': dialog}">
                <MapInstance v-bind="$props"
                             ref="dialogMap"
                             :class="{'absolute__map': dialog}"
                             @update-coordinates="updateCoordinates($event)"></MapInstance>
            </div>
        </portal>

        <MapInstance v-bind="$props"
                     v-else
                     ref="dialogMap"
                     :show-control="!dialog"
                     @zoom-map="dialog = true"
                     @update-coordinates="updateCoordinates($event)"></MapInstance>
    </div>
</template>

<script>
import MapInstance from '@/components/widgets/map/MapInstance';

export default {
    name: 'Map',
    components: { MapInstance },
    props: {
        lat: {
            type: [String, Number],
            'default': ''
        },
        lng: {
            type: [String, Number],
            'default': ''
        },
        draggable: {
            type: Boolean,
            'default': false
        },
        additionalMarker: {
            type: Boolean,
            'default': false
        },
        additionalMarkerLat: {
            type: [String, Number],
            'default': ''
        },
        additionalMarkerLng: {
            type: [String, Number],
            'default': ''
        },
        additionalMarkerCorrection: {
            type: Boolean,
            'default': false
        },
        markerDescription: {
            type: String,
            'default': ''
        },
        additionalMarkerDescription: {
            type: String,
            'default': ''
        },
        zoom: {
            type: Number,
            'default': 13
        }
    },
    data: () => ({
        dialog: false
    }),
    watch: {
        dialog: {
            handler (val) {
                if (val) {
                    this.$refs.dialogMap.onResize();
                }
            }
        }
    },
    methods: {
        updateCoordinates (event) {
            this.$emit('update-coordinates', event);
        }
    }
};
</script>

<style scoped lang="scss">
    .sheet {
        height: 100%;
        width: 100%;
    }

    .absolute {
        position: fixed;
        z-index: 99999999999;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(0,0,0,0.38);
        &__map {
            width: 60%;
            height: 60%;
        }
    }
</style>
