<template>
    <div class="pa-4">
        <h1 class="mb-5 text--xl font-weight-medium">{{ $t('navigation.incidents') }}</h1>
        <FiltersBar ref="filtersBar"></FiltersBar>
        <IncidentsTable @change-page="changePage($event)"
                        @change-amount="changeAmount($event)"
                        @sort="sortingColumn = $event"
                        @fetch="fetchIncidents"
                        @get-file="getXLS"
                        data-test="incidentsTable"
                        :xls-loader="xlsLoader"
                        :changed-page="pagination.page"
                        :loader="loader"></IncidentsTable>

        <ManageIncident @fetch="fetchIncidents"></ManageIncident>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import FiltersBar from '@/components/widgets/tables/FiltersBar';
import IncidentsTable from '@/components/incidents/IncidentsTable';
import ManageIncident from '@/components/incidents/manageIncident/ManageIncident';
import debounce from 'lodash.debounce';
import { FILTERS, FILTERS_SEARCHING, FILTERS_V2 } from '@/constants/filtersModelNames';
import debouncedSites from '@/mixins/debounced/debouncedSites';
import debouncedResponsibleEmployee from '@/mixins/debounced/debouncedResponsibleEmployee';
import debouncedEmployees from '@/mixins/debounced/debouncedEmployees';
import debouncedIncidentStatuses from '@/mixins/debounced/debouncedIncidentStatusesV2';
import debouncedIncidentTypes from '@/mixins/debounced/debouncedIncidentTypesV2';
import filtering from '@/mixins/filtering';
import DatePickerFilter from '@/models/filters/datePickerFilter';
import AutocompleteFilter from '@/models/filters/autocompleteFilter';
import { CONVERT_TO_UTC } from '@/helpers/dates';
import paginationSettings from '@/mixins/paginationSettings';
import { mapFilterForXLS } from '@/utils/incidents';

export default {
    name: 'Incidents',
    components: { FiltersBar, IncidentsTable, ManageIncident },
    mixins: [
        debouncedSites,
        debouncedEmployees,
        debouncedResponsibleEmployee,
        filtering,
        debouncedIncidentStatuses,
        debouncedIncidentTypes,
        paginationSettings
    ],
    data: (vm) => ({
        debouncedFetchIncidents: null,
        pagination: vm.setupPagination(),
        sortingColumn: null,
        loader: false,
        xlsLoader: false,
        incidentsUpdater: null
    }),
    computed: {
        filtersConfig () {
            return [
                new AutocompleteFilter({
                    model: FILTERS_V2.SITE_ID,
                    label: this.$t('labels.sites'),
                    loading: true,
                    multiple: true,
                    search: FILTERS_SEARCHING.SITE,
                    lazy: this.getSitesLazyLoading,
                    searchCallback: this.debouncedGetSites,
                    items: {
                        obj: 'getters',
                        module: 'sites',
                        prop: 'sites'
                    }
                }),
                new AutocompleteFilter({
                    model: FILTERS_V2.PERSON_ID,
                    label: this.$t('labels.reporting_person'),
                    loading: true,
                    multiple: true,
                    search: FILTERS_SEARCHING.EMPLOYEES,
                    lazy: this.getEmployeesLazyLoading,
                    searchCallback: this.debouncedGetEmployees,
                    items: {
                        obj: 'getters',
                        module: 'employees',
                        prop: 'employees'
                    }
                }),
                new AutocompleteFilter({
                    model: FILTERS_V2.PERSON_ASSIGNED_ID,
                    label: this.$t('labels.responsible_person'),
                    loading: true,
                    multiple: true,
                    search: FILTERS_SEARCHING.PERSON_ASSIGNED,
                    lazy: this.getResponsibleEmployeesLazyLoading,
                    searchCallback: this.debouncedGetResponsibleEmployees,
                    items: {
                        obj: 'getters',
                        module: 'employees',
                        prop: 'responsibleEmployees'
                    }
                }),
                new AutocompleteFilter({
                    model: FILTERS_V2.INCIDENT_TYPE_ID,
                    label: this.$t('navigation.incidents_types_reports'),
                    loading: true,
                    multiple: true,
                    search: FILTERS_SEARCHING.INCIDENT_TYPE,
                    lazy: this.getIncidentsTypesLazyLoading,
                    referralCallback: this.referralTypeCallback,
                    searchCallback: this.debounceGetTypes,
                    items: {
                        obj: 'getters',
                        module: 'incidents',
                        prop: 'filterableTypesList'
                    }
                }),
                new AutocompleteFilter({
                    model: FILTERS_V2.INCIDENT_STATUS_ID,
                    label: this.$t('navigation.statuses'),
                    loading: false,
                    multiple: true,
                    disabled: true,
                    successMessages: this.$t('messages.choose_incident_type'),
                    search: FILTERS_SEARCHING.INCIDENT_STATUS,
                    lazy: this.getIncidentStatusesLazyLoading,
                    searchCallback: this.debounceGetStatus,
                    items: {
                        obj: 'getters',
                        module: 'incidents',
                        prop: 'filterableStatusesList'
                    }
                }),
                new DatePickerFilter({
                    model: FILTERS_V2.START_DATE,
                    label: this.$t('labels.date_from')
                }),
                new DatePickerFilter({
                    model: FILTERS_V2.END_DATE,
                    label: this.$t('labels.date_to')
                })
            ];
        },
        ...mapState('filters', [
            'filters',
            'filtersSearching'
        ]),
        ...mapState('incidents', [
            'newIncidentsNotification'
        ]),
        ...mapFields('search', [
            'search'
        ]),
        ...mapState('employees', [
            'totalEmployeesAmount',
            'totalResponsibleEmployeesAmount'
        ]),
        ...mapGetters('employees', [
            'employees',
            'responsibleEmployees'
        ]),
        ...mapFields('manageIncident', [
            'incidentFormVisibility',
            'editedIncidentId',
            'activeIncident',
            'showDetails'
        ])
    },
    watch: {
        sortingColumn: {
            handler () {
                this.fetchIncidents();
            },
            deep: true
        },
        search: {
            handler (val, oldVal) {
                if ((val && val.length >= 2) || (oldVal && val === '')) {
                    this.pagination.page = 1;
                    this.debouncedFetchIncidents();
                }
            }
        }
    },
    methods: {
        fetchIncidents (updateElements = false) {
            if (this.pagination.page !== 1) {
                this.destroyAutoRefresh();
            } else {
                this.autoUpdateIncidents();
            }
            this.loader = !updateElements;

            const params = {
                'page': this.pagination.page,
                'page_size': this.pagination.amount,
                'ordering': '-date_of_occurrence',
                ...this.generateFilters()
            };

            if (this.sortingColumn) {
                params.ordering = this.sortingColumn.value === 'asc' ? this.sortingColumn.name : `-${this.sortingColumn.name}`;
            }

            if (this.search) {
                params.search = this.search;
            }
            return this.$store.dispatch(updateElements ? 'incidents/updateIncidents' : 'incidents/getIncidents', {
                params: params
            })
                .finally(() => {
                    this.loader = false;
                });
        },
        generateFilters (forXLS = false) {
            const params = {};

            this.filtersConfig.forEach(filter => {
                const f = filter.model;

                if (this.filters[f] && this.filters[f].length > 0) {
                    const filterName = forXLS ? `filter[${mapFilterForXLS(f)}]` : f;
                    if (f === FILTERS_V2.START_DATE || f === FILTERS.START_DATE) {
                        params[`${filterName}`] = CONVERT_TO_UTC(`${this.filters[f]} 00:00:00`);
                    } else if (f === FILTERS_V2.END_DATE || f === FILTERS.END_DATE) {
                        params[`${filterName}`] = CONVERT_TO_UTC(`${this.filters[f]} 23:59:59`);
                    } else {
                        params[`${filterName}`] = this.filters[f].join(',');
                    }
                }
            });

            return params;
        },
        changePage (event) {
            this.pagination.page = event;
            this.fetchIncidents();
        },
        changeAmount (event) {
            this.pagination.page = 1;
            this.pagination.amount = event;
            this.fetchIncidents();
        },
        getXLS () {
            this.xlsLoader = true;

            const params = {
                instance: 'incidents',
                prefix: 'event',
                ...this.generateFilters(true)
            };

            if (this.search) {
                params['filter[search]'] = this.search;
            }

            return this.$store.dispatch('commonActions/getXLS', params)
                .finally(() => {
                    this.xlsLoader = false;
                });
        },
        autoUpdateIncidents () {
            this.destroyAutoRefresh();
            this.incidentsUpdater = setInterval(() => {
                this.fetchIncidents(true);
            }, 5000);
        },
        destroyAutoRefresh () {
            clearInterval(this.incidentsUpdater);
        }
    },
    created () {
        this.debouncedFetchIncidents = debounce(this.fetchIncidents, 1000);
        if (this.newIncidentsNotification) {
            this.$store.commit('incidents/SET_NEW_INCIDENTS_NOTIFICATION', false);
        }
    },
    mounted () {
        this.setFiltersWatcher(this.debouncedFetchIncidents);
    },
    beforeDestroy () {
        this.destroyAutoRefresh();
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            if (vm.$can(vm.$abilityActions.READ, vm.$abilitySubjects.INCIDENT)) {
                const settingsParams = {
                    'page[number]': -1,
                    'filter[group]': 'Identifier,General_settings,General_settings_incident'
                };
                vm.$store.dispatch('systemSettings/getSettings', settingsParams).finally(() => {
                    vm.fetchIncidents();
                });
                if (to.params.id) {
                    vm.editedIncidentId = to.params.id;
                    vm.$store.dispatch('manageIncident/getActiveIncident', to.params.id)
                        .then(() => {
                            if (vm.activeIncident.source_inspection_id) {
                                vm.$store.dispatch('manageInspection/getActiveInspection', vm.activeIncident.source_inspection_id);
                            }
                            vm.showDetails = true;
                        });
                }
                vm.$store.commit('filters/SET_CONFIG', vm.filtersConfig);
                vm.$store.dispatch('employees/getEmployees', { 'filter[is_item]': false })
                    .then(() => {
                        vm.filtersConfig.find(el => el.model === FILTERS_V2.PERSON_ID).loading = false;
                        vm.$store.commit('filters/SET_CONFIG', vm.filtersConfig);
                    });
                vm.$store.dispatch('employees/getResponsibleEmployees')
                    .then(() => {
                        vm.filtersConfig.find(el => el.model === FILTERS_V2.PERSON_ASSIGNED_ID).loading = false;
                        vm.$store.commit('filters/SET_CONFIG', vm.filtersConfig);
                    });
                vm.$store.dispatch('sites/getSites')
                    .then(() => {
                        vm.filtersConfig.find(el => el.model === FILTERS_V2.SITE_ID).loading = false;
                        vm.$store.commit('filters/SET_CONFIG', vm.filtersConfig);
                    });
                vm.$store.dispatch('incidents/getIncidentTypes')
                    .then(() => {
                        vm.filtersConfig.find(el => el.model === FILTERS_V2.INCIDENT_TYPE_ID).loading = false;
                        vm.$store.commit('filters/SET_CONFIG', vm.filtersConfig);
                    });
                vm.fetchIncidents();
            } else {
                vm.$router.push({ name: 'home' });
            }
        });
    },
    beforeRouteLeave (to, from, next) {
        this.$store.dispatch('filters/clearFiltersParams');
        this.search = null;
        next();
    }
};
</script>

<style scoped>

</style>
