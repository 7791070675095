<template>
    <div>
        <h4 class="font-weight-medium text--m">{{ comment.employee_name }}</h4>
        <span class="text--xs">{{ comment.date_time }}</span>
        <p class="mt-1 mb-4 white-space">{{ comment.comment }}</p>
    </div>
</template>

<script>
export default {
    name: 'Comment',
    props: {
        comment: {
            type: Object,
            required: true
        }
    }
};
</script>

<style scoped lang="scss">
    .white-space {
        white-space: pre-wrap;
    }
</style>
