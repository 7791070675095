import { FILTERS, FILTERS_V2 } from '@/constants/filtersModelNames';

export const mapFilterForXLS = (filter) => {
    switch (filter) {
    case FILTERS_V2.SITE_ID:
        return FILTERS.SITE_ID;
    case FILTERS_V2.PERSON_ID:
        return FILTERS.PERSON_ID;
    case FILTERS_V2.PERSON_ASSIGNED_ID:
        return FILTERS.PERSON_ASSIGNED_ID;
    case FILTERS_V2.INCIDENT_TYPE_ID:
        return FILTERS.INCIDENT_TYPE_ID;
    case FILTERS_V2.INCIDENT_STATUS_ID:
        return FILTERS.INCIDENT_STATUS_ID;
    case FILTERS_V2.START_DATE:
        return FILTERS.START_DATE;
    case FILTERS_V2.END_DATE:
        return FILTERS.END_DATE;
    default:
        return '';
    }
};
