<template>
    <Modal :modal-title="$t('incidents.add_comment')"
           :dialog="dialog"
           max-width="1000px"
           :persistent="true"
           :closeIcon="false"
           dense
           @keydown.enter="showConfirmation"
           @keydown.esc="closeWithConfirmation"
           @close="close"
           @visibility="visibility($event)">
        <template slot="content">
            <ValidationObserver ref="obs">
                <TextareaField :label="$tc('labels.comment', 1)"
                               name="comment"
                               rules="required"
                               v-model="incidentCommentContent"></TextareaField>
            </ValidationObserver>
            <ConfirmationDialog :dialog="showConfirmationDialog"
                                @confirm="handleConfirmation($event)"
                                :text="$t('messages.save_changes')"
                                :action-button-title="$t('buttons.save')"></ConfirmationDialog>
            <ValidationError v-if="showError"></ValidationError>
        </template>

        <template slot="actions">
            <v-layout align-center
                      justify-end
                      class="mt-3">
                <v-btn color="secondary"
                       outlined
                       class="mr-3"
                       data-test="closeBtn"
                       @click="close">{{ $t('buttons.cancel') }}</v-btn>
                <v-btn color="secondary"
                       data-test="saveBtn"
                       :loading="loader"
                       :disabled="loader"
                       @click="save">{{ $t('buttons.save') }}</v-btn>
            </v-layout>
        </template>
    </Modal>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import { ValidationObserver } from 'vee-validate';
import Modal from '@/components/templates/Modal';
import ConfirmationDialog from '@/components/widgets/dialogs/ConfirmationDialog';
import TextareaField from '@/components/widgets/forms/TextareaField';
import ValidationError from '@/components/widgets/ValidationError';

export default {
    name: 'AddComment',
    components: { Modal, ConfirmationDialog, ValidationObserver, TextareaField, ValidationError },
    props: {
        dialog: {
            type: Boolean,
            required: true
        }
    },
    data: () => ({
        showConfirmationDialog: false,
        loader: false,
        showError: false
    }),
    computed: {
        ...mapFields('manageIncident', [
            'incidentCommentContent',
            'activeIncident'
        ]),
        isFieldsDirty () {
            return this.$refs.obs.flags.changed;
        }
    },
    methods: {
        close () {
            this.incidentCommentContent = null;
            this.$emit('close');
        },
        visibility (event) {
            this.$emit('visibility', event);
        },
        closeWithConfirmation () {
            if (this.isFieldsDirty) {
                this.showConfirmationDialog = true;
            } else {
                this.close();
            }
        },
        showConfirmation () {
            if (this.isFieldsDirty) {
                this.showConfirmationDialog = true;
            }
        },
        save () {
            this.loader = true;

            this.$refs.obs.validate()
                .then(valid => {
                    this.showError = !valid;
                    if (valid) {
                        this.$store.dispatch('manageIncident/createComment')
                            .then(() => {
                                this.$store.dispatch('manageIncident/getCommentsPerIncident',
                                    { id: this.activeIncident.id });
                                this.close();
                            })
                            .finally(() => {
                                this.loader = false;
                            });
                    } else {
                        this.loader = false;
                    }
                });
        },
        handleConfirmation (event) {
            if (event) {
                this.save();
                this.showConfirmationDialog = false;
            } else {
                this.showConfirmationDialog = false;
            }
        }
    }
};
</script>

<style scoped>

</style>
