import { mapState } from 'vuex';

export default {
    computed: {
        ...mapState('systemSettings', [
            'settings'
        ])
    },
    methods: {
        isVisibleHeader (headerKey, negationLogic = false) {
            if (headerKey === undefined) return true;
            const locationSettings = this.settings.find(setting => setting.setting_type === headerKey);
            if (locationSettings) {
                return locationSettings.value === (negationLogic ? '0' : '1');
            }
            return false;
        }
    }
};
