<template>
    <v-sheet color="white"
             class="pa-4"
             elevation="1">
        <v-progress-linear indeterminate
                           v-if="loader"
                           color="primary"></v-progress-linear>
        <div v-if="incidentComments.length > 0">
            <InfiniteScroll v-for="(comment, i) in incidentComments"
                            :key="i"
                            :items-length="incidentComments.length"
                            :index="i"
                            :item="comment"
                            @load-more-items="getCommentsPerIncidentLazyLoading">
                <template>
                    <Comment :comment="comment"></Comment>
                </template>
            </InfiniteScroll>
        </div>
        <p v-else class="text-center">{{ $t('messages.no_comments') }}</p>

        <v-layout justify-end
                  v-if="canAddComment"
                  class="mt-3">
            <v-btn color="secondary"
                   data-test="addCommentBtn"
                   @click="showAddComment = true">{{ $t('buttons.add') }}</v-btn>
            <AddComment :dialog="showAddComment"
                        data-test="addCommentModal"
                        @visibility="showAddComment = $event"
                        @close="showAddComment = false"></AddComment>
        </v-layout>
    </v-sheet>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import AddComment from '@/components/incidents/AddComment';
import Comment from '@/components/widgets/Comment';
import InfiniteScroll from '@/components/widgets/InfiniteScroll';

export default {
    name: 'CommentsList',
    components: { Comment, AddComment, InfiniteScroll },
    data: () => ({
        showAddComment: false,
        pagination: {
            page: 1,
            amount: 20
        },
        loader: false
    }),
    computed: {
        ...mapState('manageIncident', [
            'incidentComments',
            'incidentCommentsTotalAmount'
        ]),
        ...mapGetters('manageIncident', [
            'activeIncidentId'
        ]),
        canAddComment () {
            return this.$can(this.$abilityActions.CREATE, this.$abilitySubjects.INCIDENT_COMMENTS);
        }
    },
    methods: {
        getCommentsPerIncidentLazyLoading () {
            if (this.incidentCommentsTotalAmount > this.incidentComments.length) {
                this.pagination.page += 1;
                this.loader = true;

                return this.$store.dispatch('manageIncident/getCommentsPerIncidentLazyLoading', {
                    id: this.activeIncidentId,
                    page: this.pagination.page,
                    amount: this.pagination.amount
                })
                    .finally(() => {
                        this.loader = false;
                    });
            }
        }
    }
};
</script>

<style scoped>

</style>
