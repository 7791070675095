var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-layout',{staticClass:"mb-8",attrs:{"align-center":"","justify-space-between":""}},[_c('v-flex',[_c('a',{staticClass:"mr-3",on:{"click":_vm.emitFetch}},[_c('v-icon',{attrs:{"size":"20"}},[_vm._v(_vm._s(_vm.ICONS.REFRESH))]),_vm._v(" "+_vm._s(_vm.$t('buttons.refresh_list'))+" ")],1),_c('GetFile',{attrs:{"title":_vm.$t('buttons.export_xls'),"loader":_vm.xlsLoader},on:{"get-file":_vm.emitGetFile}},[_c('template',{slot:"icon"},[_c('v-icon',{staticClass:"mr-1",attrs:{"size":"20"}},[_vm._v(_vm._s(_vm.ICONS.EXPORT)+" ")])],1)],2)],1),_c('v-flex',[_c('v-layout',{attrs:{"justify-end":""}},[(_vm.canAddIncident)?_c('v-btn',{attrs:{"color":"secondary","data-test":"addNewIncidentBtn"},on:{"click":function($event){return _vm.openEditForm()}}},[_vm._v(_vm._s(_vm.$t('incidents.add_new_incident'))+" ")]):_vm._e()],1)],1)],1),_c('Pagination',{attrs:{"pagination":_vm.pagination,"data-test":"pagination"},on:{"change-page":function($event){return _vm.changePage($event)},"change-amount":function($event){return _vm.changeAmount($event)}}}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.getHeaders(),"loading":_vm.loader,"disable-sort":"","items":_vm.incidents,"disable-pagination":"","hide-default-footer":"","show-select":"","height":"60vh","fixed-header":"","item-key":"number"},on:{"dblclick:row":_vm.showRowDetails},scopedSlots:_vm._u([_vm._l((_vm.getHeaders()),function(headerItem,i){return {key:("header." + (headerItem.value)),fn:function(ref){
var header = ref.header;
return [_c('SortableHeader',{key:i,attrs:{"header-item":header},on:{"sort":function($event){return _vm.sort($event)}}})]}}}),{key:"item.number",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"clickable",on:{"click":function($event){return _vm.showDetailsDialog(item)}}},[_vm._v(_vm._s(item.number))])]}},{key:"item.incident_type",fn:function(ref){
var item = ref.item;
return [(item.type_id)?_c('Type',{attrs:{"type":item.incident_type}}):_vm._e()]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status_id)?_c('Status',{attrs:{"status":item.status}}):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('HintingIcon',{attrs:{"class-name":"mr-2","icon":_vm.ICONS.DETAILS,"data-test":"showDetailsBtn","tooltip":_vm.$t('actions.view')},on:{"click":function($event){return _vm.showDetailsDialog(item)}}}),(_vm.canEditIncident)?_c('HintingIcon',{attrs:{"class-name":"mr-2","icon":_vm.ICONS.EDIT,"disabled":!item.is_editable,"data-test":"editIncidentBtn","tooltip":_vm.$t('actions.edit')},on:{"click":function($event){return _vm.openEditForm(item)}}}):_vm._e(),(_vm.canRemoveIncident)?_c('ButtonWithConfirmation',{attrs:{"item-id":item.id,"callback":_vm.deleteIncident}}):_vm._e()]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),(_vm.showGroupActions)?_c('v-layout',{staticClass:"mt-6",attrs:{"align-center":""}},[(_vm.canRemoveIncident)?_c('ButtonWithConfirmation',{attrs:{"item-id":_vm.selected,"callback":_vm.deleteManyIncidents}},[[_c('a',{staticClass:"mr-3"},[_c('v-icon',{attrs:{"size":"20"}},[_vm._v(_vm._s(_vm.ICONS.DELETE))]),_vm._v(" "+_vm._s(_vm.$t('buttons.remove_selected'))+" ")],1)]],2):_vm._e(),(_vm.canEditIncident)?_c('div',{staticClass:"mr-3"},[_c('a',{on:{"click":_vm.showChangeStatuses}},[_c('v-icon',{attrs:{"size":"20"}},[_vm._v(_vm._s(_vm.ICONS.REPEAT))]),_vm._v(" "+_vm._s(_vm.$t('buttons.change_status_of_selected'))+" ")],1),_c('ChangeStatuses',{attrs:{"dialog":_vm.changeStatusesVisibility,"incidents":_vm.selected},on:{"visibility":function($event){_vm.changeStatusesVisibility = $event},"changed-statuses":_vm.handleChangedStatuses,"close":_vm.closeChangeStatuses}})],1):_vm._e()],1):_vm._e(),(_vm.activeIncident)?_c('IncidentDetails',{attrs:{"dialog":_vm.showDetails,"data-test":"detailsDialog"},on:{"visibility":function($event){_vm.showDetails = $event},"close":_vm.closeDetailsDialog}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }