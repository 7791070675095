<template>
    <Modal :dialog="dialog"
           titlePadding
           :closeIcon="false"
           max-width="400px"
           data-test="modal"
           @close="close"
           dense
           @visibility="visibility($event)">
        <template slot="content">
            <ValidationObserver ref="obs">
                <RadioGroup v-if="!isTypeSingle"
                            :label="$t('messages.choose_one_type')"
                            v-model="type"
                            rules="required"
                            name="type"
                            alternative-validation-name="type"
                            :options="selectedTypes">
                </RadioGroup>
                <Autocomplete name="statuses"
                              v-model="selectGroupStatus"
                              :label="$t('labels.status')"
                              rules="required"
                              clearable
                              :disabled="!disabledStatusField"
                              :success-messages="showStatusSuccessMessage"
                              :loading="isStatusLoading"
                              :search-input.sync="searchStatus"
                              @click:clear="getIncidentStatuses"
                              @load-more-items="getIncidentStatusesLazyLoading"
                              :items="filterableStatusesList"></Autocomplete>
            </ValidationObserver>
            <ValidationError v-if="showError"></ValidationError>
        </template>
        <template slot="actions">
            <v-layout align-center
                      justify-end>
                <v-btn color="secondary"
                       outlined
                       class="mr-3"
                       data-test="closeBtn"
                       @click="close">{{ $t('buttons.cancel') }}</v-btn>
                <v-btn color="secondary"
                       data-test="saveBtn"
                       :loading="loader"
                       :disabled="loader"
                       @click="save">{{ $t('buttons.save') }}</v-btn>
            </v-layout>
        </template>
    </Modal>
</template>

<script>
import { mapGetters } from 'vuex';
import { ValidationObserver } from 'vee-validate';
import Modal from '@/components/templates/Modal';
import Autocomplete from '@/components/widgets/forms/Autocomplete';
import RadioGroup from '@/components/widgets/forms/RadioGroup';
import ValidationError from '@/components/widgets/ValidationError';
import debouncedIncidentStatuses from '@/mixins/debounced/debouncedIncidentStatuses';

export default {
    name: 'ChangeStatuses',
    mixins: [debouncedIncidentStatuses],
    components: { ValidationObserver, Modal, Autocomplete, RadioGroup, ValidationError },
    props: {
        dialog: {
            type: Boolean,
            required: true
        },
        incidents: {
            type: Array,
            required: true
        }
    },
    data: () => ({
        selectGroupStatus: null,
        type: null,
        loader: false,
        showError: false
    }),
    computed: {
        ...mapGetters('incidents', [
            'filterableStatusesList'
        ]),
        selectedTypes () {
            const _arr = this.incidents.map(el => ({
                value: el.incident_type.id,
                text: el.incident_type.name
            }));
            const result = [];
            const map = new Map();
            _arr.forEach(item => {
                if (!map.has(item.value)) {
                    map.set(item.value, true);
                    result.push(item);
                }
            });
            return result;
        },
        isTypeSingle () {
            return this.selectedTypes.length === 1;
        },
        showStatusSuccessMessage () {
            return this.type !== null ? '' : this.$t('messages.choose_incident_type');
        },
        disabledStatusField () {
            return this.type !== null;
        },
        incidentsForChanging () {
            return this.incidents.filter(el => parseInt(el.incident_type.id) === parseInt(this.type));
        }
    },
    watch: {
        dialog: {
            handler (val) {
                if (val && this.isTypeSingle) {
                    this.type = this.selectedTypes[0].value;
                }
            },
            immediate: true
        },
        type: {
            handler (val, oldVal) {
                if (val !== null && val !== oldVal) {
                    this.getIncidentStatuses();
                }
            },
            immediate: true
        }
    },
    methods: {
        close () {
            this.$emit('close');
            this.$refs.obs.reset();
            this.showError = false;
            this.type = null;
        },
        visibility (event) {
            this.$emit('visibility', event);
        },
        changeStatus () {
            const payload = {
                incidentStatusId: parseInt(this.selectGroupStatus),
                incidentsIdsToUpdate: this.incidentsForChanging.map(el => parseInt(el.id))
            };

            return this.$store.dispatch('manageIncident/updateManyStatuses', payload);
        },
        save () {
            this.loader = true;

            this.$refs.obs.validate()
                .then(valid => {
                    this.showError = !valid;
                    if (valid) {
                        return this.changeStatus()
                            .then(() => {
                                this.$emit('changed-statuses');
                                this.close();
                            })
                            .finally(() => {
                                this.loader = false;
                            });
                    } else {
                        this.loader = false;
                    }
                });
        }
    }
};
</script>

<style scoped>

</style>
