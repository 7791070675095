<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div>
        <v-data-table :headers="getHeaders()"
                      :items="question"
                      disable-pagination
                      dense
                      hide-default-footer
                      class="elevation-1">
            <template v-slot:item="{ item, index }">
                <InfiniteScroll tag="tr"
                                :items-length="question.length"
                                :index="index"
                                :item="item"
                                @load-more-items="emitLazyLoading">
                    <template>
                        <td :class="{'font-weight-bold': item.question_type === TYPES.HEADER}" :colspan="1 + showHeaderOnly(item.question_type)">{{ item.question }}</td>
                        <td v-if="!showHeaderOnly(item.question_type)">
                            <Component v-if="hasKey(item.question_type)"
                                       :is="configureComponent(item).name"
                                       v-bind="configureComponent(item).attributes"></Component>
                            <span v-else
                                  :class="{'red--text fot-weight-medium': item.is_incorrect}">{{ getText(item) }}</span>
                        </td>
                        <td v-if="isVisibleHeader('HIDE_TIMESTAMP', true)">
                            <span v-if="!showHeaderOnly(item.question_type)">{{ item.answer_date_time }}</span>
                        </td>
                    </template>
                </InfiniteScroll>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { HEADER_ONLY_TYPES, TYPES } from '@/constants/questionTypes';
import Rating from '@/components/questions/Rating';
import MultipleChoice from '@/components/questions/MultipleChoice';
import Percent from '@/components/questions/Percent';
import SingleChoice from '@/components/questions/SingleChoice';
import YesNo from '@/components/questions/YesNo';
import PicturesAnswers from '@/components/questions/PicturesAnswers';
import InfiniteScroll from '@/components/widgets/InfiniteScroll';
import configQuestionComponent from '@/mixins/configQuestionComponent';
import visibleHeaders from '@/mixins/visibleHeaders';
import QuestionText from '@/components/questions/QuestionText';
import QuestionDate from '@/components/questions/QuestionDate';
import QuestionTime from '@/components/questions/QuestionTime';
import QuestionValue from '@/components/questions/QuestionValue';
import QuestionInformation from '@/components/questions/QuestionInformation';
import QuestionHeader from '@/components/questions/QuestionHeader';

export default {
    name: 'QuestionsTable',
    components: { Rating, MultipleChoice, Percent, SingleChoice, YesNo, PicturesAnswers, InfiniteScroll, QuestionText, QuestionDate, QuestionTime, QuestionValue, QuestionInformation, QuestionHeader },
    mixins: [configQuestionComponent, visibleHeaders],
    props: {
        question: {
            type: Array,
            required: true
        }
    },
    data: () => ({
        pagination: {
            page: 1,
            amount: 20
        },
        TYPES
    }),
    computed: {
        ...mapState('manageInspection', [
            'inspectionQuestionsTotalAmount'
        ]),
        ...mapState('manageTemplate', [
            'activeTemplate'
        ])
    },
    methods: {
        emitLazyLoading () {
            if (this.inspectionQuestionsTotalAmount > this.question.length) {
                this.pagination.page += 1;
                this.$emit('load-more-items', this.pagination.page);
            }
        },
        getHeaders () {
            const headers = [
                {
                    text: this.$tc('labels.question', 1),
                    value: 'question',
                    sortable: false,
                    width: '40%'
                },
                {
                    text: this.$t('labels.answer'),
                    value: 'answer',
                    sortable: false
                },
                {
                    text: this.$t('labels.date'),
                    value: 'answer_date_time',
                    sortable: false,
                    visibilityKey: 'HIDE_TIMESTAMP',
                    negationLogic: true
                }
            ];
            return headers.filter(e => this.isVisibleHeader(e.visibilityKey, !!e.negationLogic));
        },
        showHeaderOnly (type) {
            return HEADER_ONLY_TYPES.includes(type);
        }
    }
};
</script>

<style scoped>

</style>
