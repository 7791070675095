<template>
    <div>
        <p>{{ label }}</p>
        <ValidationProvider
            :name="label"
            :vid="id"
        >
            <input
                type="hidden"
                :name="id"
                :value="label"
            />
        </ValidationProvider>
    </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

export default {
    name: 'QuestionInformation',
    components: { ValidationProvider },
    props: {
        id: {
            type: String
        },
        label: {
            type: String,
            'default': ''
        }
    }
};
</script>
