<template>
    <span v-if="readonly">{{ questionValue }}</span>
    <TimePicker
        v-else-if="!notApplicable"
        :label="label"
        :disabled="readonly"
        :name="id"
        v-model="questionValue"
        :rules="required ? {required: true} : {}"
    />
</template>

<script>
import TimePicker from '@/components/widgets/forms/TimePicker';
export default {
    name: 'QuestionTime',
    components: { TimePicker },
    data: () => ({
        questionValue: null
    }),
    props: {
        id: {
            type: String
        },
        value: {
            type: String,
            'default': ''
        },
        label: {
            type: String,
            'default': ''
        },
        readonly: {
            type: Boolean,
            'default': true
        },
        required: {
            type: Boolean,
            'default': false
        },
        notApplicable: {
            type: Boolean,
            'default': false
        }
    },
    created () {
        this.questionValue = this.value;
    }
};
</script>
