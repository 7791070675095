<template>
    <v-simple-table dense>
        <template slot="default">
            <thead>
            <tr>
                <th class="text-left font-weight-medium"
                    v-for="(item, i) in headers"
                    :key="i">
                    {{ item }}
                </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, i) in log"
                :key="i">
                <td>{{ item.date }}</td>
                <td>{{ item.status_name }}</td>
                <td>{{ item.employee_name }}</td>
            </tr>
            </tbody>
        </template>
    </v-simple-table>
</template>

<script>
export default {
    name: 'StatusesLog',
    props: {
        log: {
            type: Array,
            required: true
        }
    },
    computed: {
        headers () {
            return [
                this.$t('labels.date'),
                this.$t('labels.status'),
                this.$t('labels.employee')
            ];
        }
    }
};
</script>

<style scoped>

</style>
