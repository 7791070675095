import debounce from 'lodash.debounce';
import { mapState, mapGetters } from 'vuex';
import { FILTERS_V2, FILTERS_SEARCHING } from '@/constants/filtersModelNames';

export default {
    data: () => ({
        searchStatus: null,
        isStatusLoading: false,
        debounceGetStatus: null,
        statusPaginationPage: 1
    }),
    computed: {
        ...mapState('incidents', [
            'totalIncidentStatusAmount'
        ]),
        ...mapGetters('incidents', [
            'filterableStatusesList'
        ])
    },
    watch: {
        searchStatus: {
            handler (val, oldVal) {
                if (!val || this.isStatusLoading || val === oldVal) {
                    return;
                }

                this.debounceGetStatus(val);
            }
        }
    },
    methods: {
        getIncidentStatuses (additionalParams = null) {
            this.isStatusLoading = true;

            let params = {};

            if (typeof additionalParams === 'string') {
                params['filter[name][match]'] = additionalParams;
            } else if (typeof additionalParams === 'object') {
                params = { ...additionalParams };
            }

            let typeId;

            if (this.type !== null && this.type !== undefined) {
                typeId = this.type;
            } else if (this.filters?.[FILTERS_V2.INCIDENT_TYPE_ID] !== null && this.filters?.[FILTERS_V2.INCIDENT_TYPE_ID] !== undefined) {
                typeId = this.filters?.[FILTERS_V2.INCIDENT_TYPE_ID];
            }

            if (typeId !== null && typeId !== undefined) {
                typeId = Array.isArray(typeId) ? typeId.join(',') : typeId;
                params['filter[incident_kind_id]'] = typeId;
            }

            return this.$store.dispatch('incidents/getIncidentStatuses', params)
                .then(() => {
                    this.statusPaginationPage = 1;
                })
                .finally(() => {
                    this.isStatusLoading = false;
                });
        },
        getIncidentStatusesLazyLoading (additionalParams = null) {
            if (this.totalIncidentStatusAmount > this.filterableStatusesList.length) {
                this.isStatusLoading = true;

                this.statusPaginationPage += 1;

                let params = {};

                if (typeof additionalParams === 'object') {
                    params = { ...additionalParams };
                }

                params['page[number]'] = this.statusPaginationPage;

                const _name = this.searchStatus || this.filtersSearching?.[FILTERS_SEARCHING.INCIDENT_STATUS];

                if (_name) {
                    params['filter[name][match]'] = _name;
                }

                let typeId;

                if (this.type !== null && this.type !== undefined) {
                    typeId = this.type;
                } else if (this.filters?.[FILTERS_V2.INCIDENT_TYPE_ID] !== null && this.filters?.[FILTERS_V2.INCIDENT_TYPE_ID] !== undefined) {
                    typeId = this.filters?.[FILTERS_V2.INCIDENT_TYPE_ID];
                }

                if (typeId !== null && typeId !== undefined) {
                    typeId = Array.isArray(typeId) ? typeId.join(',') : typeId;
                    params['filter[incident_kind_id]'] = typeId;
                }

                return this.$store.dispatch('incidents/getIncidentStatusesLazyLoading', params)
                    .finally(() => {
                        this.isStatusLoading = false;
                    });
            }
        }
    },
    created () {
        this.debounceGetStatus = debounce(this.getIncidentStatuses, 1000);
    }
};
